import React, {useEffect} from 'react';
import logo from './logo.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import image from'./toms.jpeg'

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="App">
      <div style={{
        width:'100%',
        height:'100vh',
        maxHeight:'100vh',
        backgroundColor:'#000',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
      }}>
        <div data-aos="fade-up" style={{
          width:'100%',
          height:'80vh',
          display:'flex',
          flexDirection:'column',
          alignContent:'center',
          alignItems:'center',
          justifyContent:'center'
        }}>
          <div 
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              maxWidth: '800px',
              height: 'auto',
              aspectRatio: '16/9',
          }}/>          
        </div>
            <a href='mailto:info@tomsracing.com.ar' style={{
              width:'100%',
              height:'5vh',
              fontFamily:'semi_bold_italic',
              color:'#fff',
              fontSize:'1.5em',
              textAlign:'center',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              textDecoration:'none'
            }}>info@toms<div style={{color:'#e60012'}}>r</div>acing.com.ar</a>
        </div>
    </div>
  );
}
export default App;
